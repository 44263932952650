<template>
  <v-form v-if='editFormObj.ofw'>

    <v-select
      :items="contractCategoryData"
      v-model="editFormObj.ofw.contractCategory"
      label='Contract Category'
    >
    </v-select>

    <v-text-field
      label='Contract Number'
      v-model='editFormObj.ofw.contractNumber'
    >
    </v-text-field>

    <v-text-field
      label='OwwA Receipt Number'
      v-model='editFormObj.ofw.OwwaNum'
    >
    </v-text-field>

    <v-select
      v-model='editFormObj.ofw.maritalStatus'
      label='Marital Status'
      :items='["Single","Married"]'
    >
    </v-select>

    <v-text-field
      label='Maid Visa Expires'
      v-model='editFormObj.ofw.visaExpiresOn'
    >
    </v-text-field>
    <v-text-field
      label='Maid Passport Valid Until'
      v-model='editFormObj.ofw.passportValidUntil'
    >
    </v-text-field>

    <v-text-field
      label='Maid Contact Number'
      v-model='editFormObj.ofw.maidNumber'
    >
    </v-text-field>

    <v-text-field
      label='Maid Contact Person/Relationship'
      v-model='editFormObj.ofw.maidContactPerson'
    >
    </v-text-field>

    <v-text-field
      label='Customer Passport Number'
      v-model='editFormObj.ofw.customerPassportNum'
    >
    </v-text-field>
    <v-text-field
      label='Customers HKID Number'
      :rules='hkIDRule'
      v-model='editFormObj.ofw.customerHKID'
    >
    </v-text-field>
    <v-text-field
      label='Last Name of Employers Spouse'
      v-model='editFormObj.ofw.customerSpouseLastName'
    >
    </v-text-field>

    <v-text-field
      label='First Name of Employers Spouse'
      v-model='editFormObj.ofw.customerSpouseFirstName'
    >
    </v-text-field>

    <v-text-field
      label='Middle Name of Employers Spouse'
      v-model='editFormObj.ofw.customerSpouseMiddleName'
    >
    </v-text-field>

    <v-text-field
      label='Hong Kong ID Number of Employers Spouse'
      :rules='hkIDRule'
      v-model='editFormObj.ofw.customerSpouseHKID'
    >
    </v-text-field>

    <v-text-field
      label='Passport Number of Employers Spouse'
      v-model='editFormObj.ofw.customerSpousePassportNum'
    >
    </v-text-field>

    <v-text-field
      label='Maid Phone Number'
      v-model='editFormObj.ofw.maidPhone'
    >
    </v-text-field>

    <v-text-field
      label='Philippine Agency'
      v-model='editFormObj.ofw.philippineAgency'
    >
    </v-text-field>

    <v-text-field
      label='Hong Kong Agency'
      v-model='editFormObj.ofw.hkAgency'
    >
    </v-text-field>

    <v-text-field
      label='Hong Kong Agency Phone Number'
      v-model='editFormObj.ofw.hkAgencyPhone'
    >
    </v-text-field>

    <v-text-field
      label='Philippine Agency Phone Number'
      v-model='editFormObj.ofw.philippineAgencyPhone'
    >
    </v-text-field>

    <v-btn
      :loading='saveLoading'
      @click='save'
    >
      Save
    </v-btn>
  </v-form>
</template>

<script>
import { db } from "../../firebase/firebaseConfig";
export default {
  props: {
    caseId: String,
    caseData: Object,
    maidData: Object,
  },
  mounted() {
    this.editFormObj = this.caseData;
    if (!this.editFormObj.ofw) {
      this.editFormObj.ofw = this.ofw;
    }
  },
  methods: {
    async save() {
      this.saveLoading = true;
      await db.collection("cases").doc(this.caseData.id).update(
        {
          ofw: this.editFormObj.ofw,
        },
        { merge: true }
      );
      this.saveLoading = false;
    },
  },
  data() {
    return {
      hkIDRule: [
        (v) =>
          !v ||
          /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/.test(v) ||
          "Please Enter a Valid HKID Number",
      ],
      contractCategoryData: [
        "New",
        "Recontract",
        "Transfer",
        "Finished",
        "Terminated",
      ],
      saveLoading: false,
      formChanges: false,
      editFormObj: {},
      ofw: {
        lastName: " ",
        middleName: " ",
        firstName: " ",
        addressTop1: " ",
        addressTop2: " ",
        phoneNum: " ",
        gender: " ",
        religion: " ",
        email: " ",
        position: " ",
        customerHKID: " ",
        customerAddress: " ",
        customerPhone: " ",
        amount: " ",
        hkPhoneNumber: " ",
        contractCategory: "",
        contractNumber: "",
        customerPassportNum: "",
        visaExpiresOn: "",
        OwwaNum: "",
        passportValidUntil: "",
        maidContactPerson: "",
        maidNumber: "",
        customerSpouseLastName: "",
        customerSpouseFirstName: "",
        customerSpouseMiddleName: "",
        customerSpouseHKID: "",
        customerSpousePassportNum: "",
        maidPhone: "",
      },
    };
  },
};
</script>

<style>
</style>