<template>
  <v-form v-if='editFormObj.owwa'>

    <v-text-field
      label='Maid Suffix'
      v-model='editFormObj.owwa.suffix'
    >
    </v-text-field>

    <v-text-field
      label='Maid Address Line 1 (House No. Lot No. Street Subdivion)'
      v-model='editFormObj.owwa.addressTop1'
    >
    </v-text-field>
    <v-text-field
      label='Maid Address Line 2 (Barangay City Province ZipCode)'
      v-model='editFormObj.owwa.addressTop2'
    >
    </v-text-field>
    <v-text-field
      label='Maid Phone Number'
      v-model='editFormObj.owwa.phoneNum'
    >
    </v-text-field>

    <v-text-field
      label='Maid Gender'
      v-model='editFormObj.owwa.gender'
    >
    </v-text-field>

    <v-text-field
      label='Maid Religion'
      v-model='editFormObj.owwa.religion'
    >
    </v-text-field>

    <v-text-field
      label='Maid Email'
      v-model='editFormObj.owwa.email'
    >
    </v-text-field>

    <v-text-field
      label='Maid Highest Eduction'
      v-model='editFormObj.owwa.highestEduction'
    >
    </v-text-field>

    <v-text-field
      label='Maid Course'
      v-model='editFormObj.owwa.course'
    >
    </v-text-field>

    <v-text-field
      label='Maid Civil Status'
      v-model='editFormObj.owwa.civistatus'
    >
    </v-text-field>

    <v-text-field
      label='Maid Position'
      v-model='editFormObj.owwa.position'
    >
    </v-text-field>

    <v-text-field
      label='Customer HKID'
      v-model='editFormObj.owwa.customerHKID'
    >
    </v-text-field>
    <v-text-field
      label='Customer Address'
      v-model='editFormObj.owwa.customerAddress'
    >
    </v-text-field>
    <v-text-field
      label='Customer Phone Number'
      v-model='editFormObj.owwa.customerPhone'
    >
    </v-text-field>

    <v-text-field
      label='Monthly Salary'
      v-model='editFormObj.owwa.amount'
    >
    </v-text-field>

    <p>LEGAL BENEFICIARIES / QUALIFIED DEPENDENTS</p>
    <v-row
      class='ml-1'
      :key='index'
      v-for='i,index in 3'
    >

      <v-text-field
        v-model='editFormObj.owwa["fullName" + index]'
        label="Full Name"
      ></v-text-field>

      <v-text-field
        v-model='editFormObj.owwa["relationship" + index]'
        label="Relationship"
      ></v-text-field>
      <v-text-field
        v-model='editFormObj.owwa["dob" + index]'
        label="DOB"
      ></v-text-field>
      <v-text-field
        v-model='editFormObj.owwa["address"+ index]'
        label="Complete Address"
      ></v-text-field>
      <v-text-field
        v-model='editFormObj.owwa["phone" +index]'
        label="Phone Number"
      ></v-text-field>
    </v-row>

    <v-text-field
      label='OFW HK Phone Number'
      v-model='editFormObj.owwa.hkPhoneNumber'
    >
    </v-text-field>

    <v-btn
      :loading='saveLoading'
      @click='save'
    >
      Save
    </v-btn>
  </v-form>
</template>

<script>
import { db } from "../../firebase/firebaseConfig";
export default {
  props: {
    caseId: String,
    caseData: Object,
    maidData: Object,
  },
  mounted() {
    this.editFormObj = this.caseData;
    if (!this.editFormObj.owwa) {
      this.editFormObj.owwa = this.owwa;
    }
  },
  methods: {
    async save() {
      this.saveLoading = true;
      await db.collection("cases").doc(this.caseData.id).update(
        {
          owwa: this.editFormObj.owwa,
        },
        { merge: true }
      );
      this.saveLoading = false;
    },
  },
  data() {
    return {
      saveLoading: false,
      formChanges: false,
      editFormObj: {},
      owwa: {
        suffix: " ",
        addressTop1: " ",
        addressTop2: " ",
        phoneNum: " ",
        gender: " ",
        religion: " ",
        email: " ",
        highestEduction: " ",
        course: " ",
        civistatus: " ",
        position: " ",
        customerHKID: " ",
        customerAddress: " ",
        customerPhone: " ",
        amount: " ",
        fullName1: " ",
        relationship1: " ",
        dob1: " ",
        address1: " ",
        phone1: " ",
        fullName2: " ",
        relationship2: " ",
        dob2: " ",
        address2: " ",
        phone2: " ",
        fullName0: " ",
        relationship0: " ",
        dob0: " ",
        address0: " ",
        phone0: " ",
        hkPhoneNumber: " ",
      },
    };
  },
};
</script>

<style>
</style>