import { db } from "../firebase/firebaseConfig";
import store from "../store/index";
const { collection, query, where, and, or } = require("firebase/firestore");
export const getAgents = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		let data = await db.collection("Agents").get();
		return data.docs.map((d) => d.data());
	} else {
		let data = await db
			.collection("Agents")
			.where("agentFranchasie", "==", claims.franchiseeId)
			.get();

		return data.docs.map((d) => d.data());
	}
};

export const getFranchisees = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		let data = await db.collection("Franchisees").get();
		return data.docs.map((d) => d.data());
	} else {
		return [];
	}
};

export const getDashboardData = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return {
			avaiableHelperRef: query(
				collection(db, "Helpers"),
				and(where("valid", "==", "Yes"))
			),
			allhelperRef: query(collection(db, "Helpers")),
			registerRef: query(collection(db, "register")),
			MatchResultsWithContactInfoRef: query(
				collection(db, "MatchResultsWithContactInfo")
			),
			casesRef: query(collection(db, "cases")),
		};
	} else {
		return {
			avaiableHelperRef: query(
				collection(db, "Helpers"),
				and(
					where("valid", "==", "Yes"),
					or(
						where("franchiseeId", "==", claims.franchiseeId),
						where("visibility", "==", "Share with everyone")
					)
				)
			),
			allhelperRef: query(
				collection(db, "Helpers"),
				and(or(where("visibility", "==", "Share with everyone")))
			),
			helperRef: db
				.collection("Helpers")
				.where("franchiseeId", "==", claims.franchiseeId),
			registerRef: query(
				collection(db, "register"),
				and(where("franchiseeId", "==", claims.franchiseeId))
			),
			MatchResultsWithContactInfoRef: query(
				collection(db, "MatchResultsWithContactInfo"),
				and(where("franchiseeId", "==", claims.franchiseeId))
			),
			casesRef: query(
				collection(db, "cases"),
				and(where("bio.franchiseeId", "==", claims.franchiseeId))
			),
		};
	}
};
