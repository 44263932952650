import { db } from "../firebase/firebaseConfig";
import store from "../store/index";
const {
	collection,
	query,
	where,
	and,
	or,
	getDocs,
	orderBy,
	limit,
	startAfter,
} = require("firebase/firestore");
import { customAlphabet } from "nanoid";
import axios from "axios";

export const searchHelpersForCase = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		let data = await db.collection("Helpers").where("valid", "!=", "No").get();
		data = data.docs.map((doc) => doc.data());
		return data;
	} else {
		let r = await getDocs(
			query(
				collection(db, "Helpers"),
				and(
					where("valid", "!=", "No"),
					where("visibility", "==", "Share with everyone")
				),
				orderBy("submitDate", "desc")
			)
		);
		return r.docs.map((d) => ({ id: d.id, ...d.data() }));
	}
};

export const getNextPage = async (lastdoc) => {
	let nextQuery = null;
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		nextQuery = query(
			collection(db, "Helpers"),
			orderBy("submitDate", "desc"),
			startAfter(lastdoc),
			limit(150)
		);
	} else {
		nextQuery = query(
			collection(db, "Helpers"),
			and(
				or(
					where("visibility", "==", "Share with everyone"),
					where("franchiseeId", "==", claims.franchiseeId)
				)
			),
			orderBy("submitDate", "desc"),
			startAfter(lastdoc),
			limit(150)
		);
	}

	return await getDocs(nextQuery);
};

export const getAllHelpersCount = async (myHelper) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		const q = query(collection(db, "Helpers"), orderBy("submitDate", "desc"));
		const docs = await getDocs(q);
		return docs.size;
	} else {
		if (myHelper) {
			const q = query(
				collection(db, "Helpers"),
				where("franchiseeId", "==", claims.franchiseeId),
				orderBy("submitDate", "desc")
			);
			const docs = await getDocs(q);
			return docs.size;
		} else {
			const q = query(
				collection(db, "Helpers"),
				and(
					or(
						where("visibility", "==", "Share with everyone"),
						where("franchiseeId", "==", claims.franchiseeId)
					)
				),
				orderBy("submitDate", "desc")
			);
			const docs = await getDocs(q);
			return docs.size;
		}
	}
};

export const searchHelpers = async (input, type) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		let data = await db.collection("Helpers").where(type, "==", input).get();
		data = data.docs.map((doc) => doc.data());
		return data;
	} else {
		let r = await getDocs(
			query(
				collection(db, "Helpers"),
				and(
					where("valid", "!=", "No"),
					or(
						where("visibility", "==", "Share with everyone"),
						where("franchiseeId", "==", claims.franchiseeId),
						where(type, "==", input)
					)
				)
			)
		);
		return r.docs.map((d) => ({ id: d.id, ...d.data() }));
	}
};

export const getAllHelpers = async (
	myHelper,
	available = false,
	next = false,
	lastdoc
) => {
	const claims = store.getters.claims;
	let baseQuery = query(
		collection(db, "Helpers"),
		orderBy("submitDate", "desc"),
		limit(150)
	);
	if (next) {
		baseQuery = query(
			collection(db, "Helpers"),
			orderBy("submitDate", "desc"),
			startAfter(lastdoc),
			limit(150)
		);
	}
	let finalQuery;
	let conditions = [];
	// get available helpers and not admin
	if (available && claims.role !== "admin") {
		conditions.push(
			and(
				where("valid", "==", "Yes"),
				or(where("visibility", "==", "Share with everyone"))
			)
		);
		// get my helpers
	} else if (myHelper) {
		conditions.push(where("franchiseeId", "==", claims.franchiseeId));
		// get all helpers for franchise
	} else if (!available && claims.role !== "admin") {
		conditions.push(
			or(
				where("visibility", "==", "Share with everyone"),
				where("franchiseeId", "==", claims.franchiseeId)
			)
		);
	} else if (claims.role !== "admin") {
		conditions.push(or(where("franchiseeId", "==", claims.franchiseeId)));
	} else if (claims.role === "admin" && available) {
		conditions.push(or(where("valid", "!=", "No")));
	}

	finalQuery = query(baseQuery, and(...conditions));

	return finalQuery;
};
export const getHelperByPortfolioId = (id) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return query(
			collection(db, "Helpers"),
			and(or(where("portfolioId", "==", id)))
		);
	} else {
		let r = query(
			collection(db, "Helpers"),
			and(
				where("portfolioId", "==", id),
				or(
					where("visibility", "==", "Share with everyone"),
					where("franchiseeId", "==", claims.franchiseeId)
				)
			)
		);
		return r;
	}
};

export const uploadFile = async (
	id,
	fileName,
	baseString,
	fileType,
	location
) => {
	let options = {
		"content-type": "text/json",
		"Access-Control-Allow-Origin": "*",
	};
	const claims = store.getters.claims;
	let franchiseeId;
	if (claims.role === "admin") {
		franchiseeId = "";
	} else {
		franchiseeId = claims.franchiseeId;
	}
	await axios.post(
		"https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/uploadDocument",
		{
			uploadID: id,
			fileName: fileName,
			baseString: baseString,
			fileType: fileType,
			location: location,
			franchiseeId: franchiseeId,
		},
		options
	);
};

export const createNewHelper = async (combineData) => {
	const nanoid = customAlphabet("1234567890abcdef", 8);
	combineData.id = await nanoid();
	const claims = store.getters.claims;
	if (claims.role !== "admin") {
		combineData.franchiseeId = claims.franchiseeId;
	}
	return await db.collection("Helpers").doc(combineData.id).set(combineData);
};

export const updateHelper = async (id, data) => {
	try {
		for (const key in data) {
			if (data[key] === undefined) {
				data[key] = null;
			}
		}

		console.log(data);
		await db.collection("Helpers").doc(id).set(data);
		return 200;
	} catch (e) {
		return 500;
	}
};

export const getHelper = (id) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return query(collection(db, "Helpers"), and(or(where("id", "==", id))));
	} else {
		let r = query(
			collection(db, "Helpers"),
			and(
				where("id", "==", id),
				or(
					where("visibility", "==", "Share with everyone"),
					where("franchiseeId", "==", claims.franchiseeId)
				)
			)
		);

		return r;
	}
};
